var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.reservationFeedback.OreservationId
        ? _c(
            "b-popover",
            {
              attrs: {
                target:
                  "feedback-" +
                  _vm.reservationFeedback.OreservationId +
                  "-" +
                  this.visitorFeedback,
                placement: "auto",
                triggers: "hover",
                delay: "100",
                "custom-class": "feedback-history-popover",
              },
              on: {
                show: function ($event) {
                  return _vm.showPopover()
                },
              },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: this.showTable,
                      expression: "this.showTable",
                    },
                  ],
                  staticClass: "review-history-wrapper",
                },
                [
                  this.showTable
                    ? _c("v-client-table", {
                        attrs: {
                          data: this.data,
                          columns: _vm.columns,
                          options: _vm.options,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "Date",
                              fn: function (list) {
                                return [
                                  _vm._v(
                                    _vm._s(_vm._f("dateTime")(list.row.Date))
                                  ),
                                ]
                              },
                            },
                            {
                              key: "Rating",
                              fn: function (list) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "summary-rating d-flex align-items-center pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.routeToReview(
                                            list.row.ReviewId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        staticClass: "mr-2",
                                        attrs: { icon: "star", size: "lg" },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatRating(list.row.Rating)
                                          )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          287979834
                        ),
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          attrs: {
            id:
              "feedback-" +
              _vm.reservationFeedback.OreservationId +
              "-" +
              this.visitorFeedback,
          },
        },
        [
          !_vm.reservationFeedback.OrfeedbackCancellationReasonId
            ? _c("boolean-display", {
                attrs: {
                  boolValue: _vm.reservationFeedback.OrfeedbackConfirmed,
                },
              })
            : _vm._e(),
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _vm.formatCancelReason(
                  _vm.reservationFeedback.OrfeedbackCancellationReasonId
                )
              ) +
              "\n\t\t" +
              _vm._s(_vm.reservationFeedback.OrfeedbackNotes) +
              "\n\t"
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }